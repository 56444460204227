import React from 'react';
import PropTypes from 'prop-types';
import ServiceCard from '../components/ServiceCard';

const ServiceGrid = ({input}) => (
  <div className="mt3 mb3 mt6 flex-l flex-wrap justify-between pl3 pr3 pl5-l pr5-l card-grid">
    {input.edges.map(serviceItem => (
      <ServiceCard input={serviceItem.node} classNames="w-100 w-30-l mb4" />
    ))}
  </div>
);
export default ServiceGrid;

ServiceGrid.propTypes = {
  input: PropTypes.object.isRequired
};
