import React from 'react';
import {graphql} from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import PageBanner from '../components/PageBanner';
import ServiceGrid from '../components/ServiceGrid';

const SolutionsPage = ({data: {prismicServicePortal, services}}) => {
  const {data} = prismicServicePortal;
  return (
    <Layout headerColor={data.initial_header_colour}>
      <SEO title="Welcome" keywords={['Low Doc Loans', 'Development Finance', 'Construction Finance', 'Bridging Finance', 'Non Bank Loans', 'New Build Finance']} />
      <div className="header-spacer-mobile" />
      <PageBanner bannerImage={data.banner_image} />
      <h2 className="blue center mt4 mt6-l mb3 f2 f1-l tc">{data.title.text}</h2>
      <div dangerouslySetInnerHTML={{__html: data.text.html}} className="measure-widest center tc lh-copy pl3 pr3 pl4-l pr4-l f4-l f6" />
      <ServiceGrid input={services} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    prismicServicePortal {
      data {
        banner_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1800, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        initial_header_colour
        title {
          text
        }
        text {
          html
        }
      }
    }
    services: allPrismicService(sort: {fields: [data___service_weight], order: DESC}) {
      edges {
        node {
          uid
          data {
            service_name {
              text
            }
            portal_text {
              text
            }
            service_weight
            portal_image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1800, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default SolutionsPage;
